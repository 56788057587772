import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width='43'
      height='35'
      viewBox='0 0 43 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M41.7805 4.03925C41.1089 4.33711 40.4184 4.5885 39.7129 4.7926C40.5482 3.84798 41.185 2.7365 41.5737 1.52022C41.6609 1.24759 41.5706 0.94917 41.3465 0.770864C41.1227 0.592421 40.8117 0.571046 40.5653 0.717083C39.067 1.60571 37.4505 2.24433 35.7556 2.61777C34.0482 0.949446 31.7293 0 29.332 0C24.2718 0 20.1549 4.11675 20.1549 9.17687C20.1549 9.5754 20.1801 9.97173 20.23 10.3625C13.9507 9.81121 8.113 6.72486 4.10423 1.80746C3.96136 1.63219 3.74127 1.53773 3.51594 1.55579C3.29048 1.57345 3.0879 1.70059 2.97399 1.896C2.16093 3.29114 1.7311 4.88678 1.7311 6.51029C1.7311 8.72152 2.52058 10.8195 3.91517 12.4589C3.49112 12.312 3.07963 12.1285 2.68688 11.9105C2.47603 11.7931 2.21871 11.7949 2.00924 11.915C1.79963 12.0351 1.66821 12.2561 1.6627 12.4975C1.66173 12.5382 1.66173 12.5789 1.66173 12.6201C1.66173 15.9208 3.43817 18.8924 6.15412 20.512C5.92079 20.4887 5.6876 20.4549 5.45593 20.4107C5.21708 20.365 4.97148 20.4487 4.81042 20.6309C4.64907 20.8129 4.5957 21.0667 4.67003 21.2983C5.67533 24.437 8.26359 26.7455 11.3925 27.4494C8.7974 29.0748 5.82964 29.9262 2.71419 29.9262C2.06413 29.9262 1.41034 29.888 0.77048 29.8123C0.452619 29.7745 0.148686 29.9622 0.0404341 30.2645C-0.0678179 30.5669 0.0469155 30.9041 0.317339 31.0774C4.31963 33.6436 8.94743 35 13.7002 35C23.0435 35 28.8884 30.5941 32.1463 26.8979C36.2089 22.2891 38.5388 16.1888 38.5388 10.1613C38.5388 9.90954 38.535 9.65525 38.5272 9.40179C40.1301 8.19419 41.51 6.73272 42.633 5.05295C42.8035 4.79784 42.7851 4.46067 42.5875 4.22583C42.3901 3.99084 42.0612 3.91486 41.7805 4.03925Z'
        fill='#024129'
      />
    </svg>
  );
}

export default SvgComponent;

