import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width='64px'
      height='64px'
      viewBox='-4 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Dribbble-Light-Preview'
          transform='translate(-384.000000, -7159.000000)'
          fill='#000000'
        >
          <g id='icons' transform='translate(56.000000, 160.000000)'>
            <path
              d='M338,7002 C338,7001.448 337.552,7001 337,7001 L331,7001 C330.448,7001 330,7001.448 330,7002 L330,7016 C330,7016.552 330.448,7017 331,7017 L337,7017 C337.552,7017 338,7016.552 338,7016 L338,7002 Z M340,7001 L340,7017 C340,7018.105 339.105,7019 338,7019 L330,7019 C328.895,7019 328,7018.105 328,7017 L328,7001 C328,6999.895 328.895,6999 330,6999 L338,6999 C339.105,6999 340,6999.895 340,7001 L340,7001 Z M335.021,7014 C335.021,7014.552 334.573,7015 334.021,7015 C333.469,7015 333.021,7014.552 333.021,7014 C333.021,7013.448 333.469,7013 334.021,7013 C334.573,7013 335.021,7013.448 335.021,7014 L335.021,7014 Z'
              id='phone-[#225]'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
